import { AdmobBannerSize } from '@common/constants/admob';
import { IRecord } from '@common/types/database';
import { CancelTokenSource } from 'axios';
import { FC } from 'react';
import { IOnPressProps } from './action';
import { Visibility } from 'click-types';

export type ElementType =
  | IInput
  | ILabel
  | IRectangle
  | IImage
  | ILibraryComponent
  | IPassword
  | ISelect
  | IDatePicker
  | IList
  | IImageUpload
  | IFileUpload
  | IGroup
  | IAdmob;

export type Obj = {
  [key in string | number]: any;
};
export enum ComponentType {
  image = 'image',
  button = 'button',
  input = 'input',
  label = 'label',
  rectangle = 'section',
  libraryComponent = 'libraryComponent',
  list = 'list',
  imageList = 'imageList',
  customList = 'customList',
  form = 'form',
  password = 'password',
  select = 'select',
  datePicker = 'date-picker',
  checkbox = 'checkbox',
  imageUpload = 'image-upload',
  fileUpload = 'file-upload',
  youtube = 'youtube',
  vimeo = 'vimeo',
  admob = 'admob',
  group = 'group',
  webView = 'web-view',
  lineLogin = 'lineLogin',
  docomoLogin = 'docomoLogin',
  DIDLogin = 'DIDLogin',
  stamp = 'Stamp',
  stampHistory = 'StampHistory',
  trustdockLogin = 'trustdockLogin',
  table = 'Table',
  web3List = 'web3-list',
  question = 'questions',
  liveStreamCamera = 'liveStreamCamera',
  imageUploadList = 'imageUploadList',
  inAppPurchase = 'inAppPurchase',
  customerChat = 'CustomerChat',
  livestreamChat = 'livestreamChat',
  invisibleShape = 'InvisibleShape',
}
export interface IPage {
  screenId: number;
  appId: number;
  appUuid: number;
  name: string;
  description: string;
  type: string;
  screenUuid: string;
  metadata: any;
  attributes: any;
  top: number;
  left: number;
  width: number;
  height: number;
  isModal?: boolean;
  primaryColor?: string;
  secondaryColor?: string;
}

export interface IApp {
  appId: string;
  certificatePath: string;
  cookie: any;
  createdAt: number | null;
  deletedAt?: number | null;
  description: string;
  icon: string;
  id: string;
  metadata: string;
  name: string;
  ownerId: number;
  primaryColor: string;
  secondaryColor: string;
  teamId: number;
  templateId: number;
  updatedAt: number | null;
  public: boolean;
  tags: string[];
  thumbnails: string[];
  shareDatabaseAppId: string | null;
  platform: 'web' | 'mobile';
  plan: {
    planId: string;
    name: string;
    description: string;
    active: boolean;
    permission: {
      iOS: boolean;
      android: boolean;
      capacity: number;
      zapier: number;
      PWA: boolean;
      numberOfRecords: number;
      numberOfMembers: number;
      admob: boolean;
      customDomain: boolean;
      pushNotifications: boolean;
      vimeo: boolean;
    };
  };
  isConnectUnivapay?: boolean;
  accountConnectId?: string;
  cacheExpiresOn?: string;
  stripeProductId?: string;
}

export interface IAppInfo extends Omit<IApp, 'metadata'> {
  APP_VERSION?: string;
  APP_NAME?: string;
  visibility?: Visibility | null;
  metadata: {
    active: string;
    startHomePage: string;
    planId: string;
    subId: string;
    auth: { tableId: string };
    canvasVersion?: number;
    ga4?: { id: string };
    stripe?: {
      enabledTestMode: boolean;
      publishableKey: string;
      secretKey: string;
    };
    fincode?: {
      isVerified: boolean;
      shopId?: string | null;
      testMode?: {
        shopId: string;
      } | null;
    };
    fcl?: {
      active: boolean;
      network: 'testnet' | 'mainnet';
      bloctoAppId?: string;
    };
  };
}

export type LabelAttr = {};

export interface BaseComp {
  id: any;
  actions?: Obj;
  x: number;
  y: number;
  width: number;
  height: number;
  name: string;
  type: string;
  hidden: boolean;
  opacity: number;
  marginTop?: number;
  marginLeft?: number;
  marginBottom?: number;
  borderColor: string;
  borderStyle: 'dotted' | 'solid' | 'dashed' | 'none' | undefined;
  borderWidth: number;
  positioning: any;
  borderRadius: any;
  dataBinding?: any;
  data2?: any;
  backgroundColor: string;
  backgroundStyle: string;
  screenId: number;
  zIndex: number;
  scroll?: {
    active?: boolean;
    arrange?: 'horizontal' | 'vertical';
    showsHorizontalScrollIndicator?: boolean;
  };
  fixPosition: 'bottom' | 'top' | 'none';
  defaultBottom?: any;
  space?: any;
  originX: number;
  originY: number;
  componentName: string;
  action?: IAction;
  selectedItem?: any;
  onPress: (actionId: string | null, options?: IOnPressProps) => any;
  attributes?: any;
  webOffset?: number;
  parentWidth?: number;
  parentListItemId?: string;
  parentListDatabaseUuid?: string;
  currentPage?: number;
  loadingAction: boolean;
  dependencies: any;
  groupActionId?: string;
  record: Obj;

  setEnableScrollViewScroll?: (value: boolean) => void;
  enableScrollViewScroll?: boolean;
  screenUuid: string;
  screenWidth: undefined | number;
  currentRecordIds: Record<string, any>;
}

export interface IRectangle extends BaseComp {
  type: ComponentType.rectangle;
  record: any;
  shadow?: {
    blur: number;
    color: string;
    enabled: boolean;
    size: number;
    x: number;
    y: number;
  };
  marginLeft: number;
  marginTop: number;
  borderStyle: any;
  children?: Array<any>;
  isInCustomList?: boolean;
  isBgGradient?: boolean;
  startX?: number;
  startY?: number;
  endX?: number;
  endY?: number;
  bgGradientFirst?: string;
  bgGradientSecond?: string;
  paddingBottom?: number;
}
export interface IInput extends BaseComp {
  isFixed: boolean;
  type: ComponentType.input;
  fontSize: number;
  placeholder: string;
  defaultValue: any;
  inputType: string;
  placeholderColor: string;
  icon: any;
  color: string;
  id: string;
  keyItem?: number;
  multiline: boolean;
  changeInput: (value: any) => any;
  valueInput: any;
  maxLength?: any;
  isInteger?: boolean;
  prevDefaultValue?: any;
}

export interface IStamp extends BaseComp {
  backboardImage: string;
  imprintImage: string; // for shop-stamp use, for stamp-rally users can specify image for each stamp
  validate: boolean;
}

export interface IStampHistory extends BaseComp {
  maxStampCount: number; // for shop-stamp --> use prop control, for stamp-rally --> get from stamp master
  numColumns: number;
  gap: number;
  shopStampItem: BaseComp;
  stampRallyItem: BaseComp;
  shopStampText: {
    fontSize: number;
    color: string;
  };
  stampRallyText: {
    fontSize: number;
    color: string;
  };
  shopStampImprint: {
    width: number;
    height: number;
    borderRadius: number;
  };
  stampRallyImprint: {
    width: number;
    height: number;
    borderRadius: number;
  };
}

export interface IWebView extends BaseComp {
  uri: string;
}

export interface ILabel extends BaseComp {
  type: ComponentType.label;
  isFixed: boolean;
  typeLine: string;
  text: string;
  linethrough: boolean;
  textDecoration: boolean;
  multiline: boolean;
  currentListId?: string;
}
export interface IButton extends BaseComp {
  type: ComponentType.button;
}

export interface ITable extends BaseComp {
  type: ComponentType.table;
  columns: Record<string, any>[];
  pagination: Record<string, any>;
  databaseUuid: string | undefined;
  loadingFilter: boolean;
  databaseOptions: Record<string, any>;
}

export interface IImage extends BaseComp {
  marginLeft: number;
  type: ComponentType.image;
  imageURL: any;
  imageType: 'uploaded' | 'url' | 'dynamic' | 'binding';
  imageResize: 'cover' | 'contain';
  hasPlaceholder: boolean;
  placeholderImage: string;
  cid: string;
  deltaX: number;
  deltaY: number;
  componentName: string;
  borderRadius: any;
  filename1x: string;
  imageUrl: string;
  data: Record<string, any>;
  initId?: any;
}

export interface ILibraryComponent extends BaseComp {
  componentName: string;
  componentUrl?: string;
  componentCode?: string;
  id: string;
  libraryName: string;
  positioning: string;
  attributes: any;
  type: ComponentType.libraryComponent;
  records: Array<IRecord>;
  valueInputs?: any;
  changeInput?: any;
  keyItem?: number;
  databaseUuid?: string;
  isPlugin?: boolean;
}

export interface ICardList extends BaseComp {
  type: ComponentType.imageList;
  maximum: number;
  columnCount: number;
}

export interface ICustomList extends BaseComp {
  type: ComponentType.customList;
  maximum: number;
}

export type Field = {
  fid?: string;
  fieldId: string;
  label: string;
  type:
    | 'text'
    | 'number'
    | 'password'
    | 'boolean'
    | 'image'
    | 'file'
    | 'timestamp'
    | 'select'
    | 'relationship'
    | 'date'
    | 'dateOnly'
    | 'toggle'
    | 'radio'
    | 'image-upload'
    | Obj;

  placeholder: string;
  multiline?: number;
  datePickerStyle?: any;
  required?: boolean;
  binding?: Obj;
  externalKey?: string;
  tableId?: string;
  items?: string[];
  numberOfLines?: number;
  defaultValue?: any;
};

export type AutoFields = {
  fid: string;
  multiline?: false;
  name: string;
  type: string;
  value: any[];
};

export interface ISubmitButton {
  color: string;
  backgroundColor: string;
  fontFamily: string;
  fontWeight: any;
  fontSize: number;
  text: string;
  borderRadius?: number;
  borderColor?: string;
  borderWidth?: number;
  shadow?: any;
  padding?: number;
  multiline?: number;
  marginTop?: number;
}
export interface IForm extends BaseComp {
  type: ComponentType.form;
  submitButton: ISubmitButton;
  database: {
    idCollectionSelected: string;
  };
  fields: Array<Field>;
  automaticFields: Array<AutoFields>;
  fieldStyles: {
    labels?: any;
    inputs: any;
  };
  reference: 'new' | 'login' | 'signup';
  data: object;
}

export interface IQuestion extends IForm {
  fieldStyles: {
    labels: any;
    inputs: any;
    answers: any;
    numberLabels: any;
  };
  answerQuestions: {
    text: string;
  };
}

export interface IPassword extends BaseComp {
  type: ComponentType.password;
  placeholder: string;
  placeholderColor: string;
  changeInput: (value: any) => any;
  valueInput: any;
  defaultValue: any;
}

export interface ISelect extends BaseComp {
  type: ComponentType.select;
  color: string;
  placeholderColor: string;
  fontSize: number;
  fontFamily: string;
  placeholder: string;
  shadow: any;
  data: Array<IRecord>;
  record: any;
  defaultValue: any;
  databaseUuid: string;
  numberOfLines?: number;
  borderStyle: any;
  changeInput: (value: any) => any;
  valueInput: any;
  field?: object;
  formId?: string;
  page: number;
  totalPage: number;
  onLoadMore: () => any;
  isLoadMore: boolean;
  isFormItem: boolean;
  databaseOptions: IObj;
  setDefaultValue: (value: any) => any;
}
export interface IDatePicker extends BaseComp {
  type: ComponentType.datePicker;
  shadow: any;
  defaultDateValue: 'startOfToday' | 'currentTime';
  color: string;
  defaultDateFormat: 'date' | 'dateOnly' | 'dateTextInput';
  fontSize: number;
  changeInput: (value: any) => any;
  valueInput: any;
  defaultValue: any;
}

export interface IAdmob extends BaseComp {
  androidAdID: Array<string>;
  iosAdID: Array<string>;
  bannerSize: AdmobBannerSize;
  placeholder: boolean;
  type: ComponentType.admob;
  perItem: number;
}

export interface IList extends BaseComp {
  type: ComponentType.list;
  admobs?: Array<IAdmob>;
  children: Array<ElementType>;
  databaseUuid: any;
  attributes: {
    database: {
      idCollectionSelected: number;
      options: {
        limit: number;
        sort: {
          direction: 'asc' | 'desc';
          fieldId: string;
          fieldType: string;
          value: string;
        };
      };
    };
  };
  originY: number;
  rowMargin: number;
  columnCount?: number;
  isWeb?: boolean;
  tableSelected: string;
  page: number;
  totalPage: number;
  onLoadMore: () => any;
  initializeList: boolean;
  defaultText: string;
  pageSize: number;
  isLoadMore: boolean;
}
export interface IGroup extends BaseComp {
  type: ComponentType.group;
  record: any;
  children: Array<ElementType>;
  isInCustomList: boolean;
  currentListIds: Record<string, any>;
  currentRecordIds: Record<string, any>;
  web3Config?: Record<
    string,
    Record<'address' | 'token_address' | 'network' | 'apiToken', string>
  >;
  currentListId: string;
}
export interface ICheckBox {
  id: string;
  type: ComponentType.checkbox;
  activeColor?: string;
  inactiveColor?: string;
  activeIcon?: string;
  inactiveIcon: string;
  dependencies: any;
}

export interface IImageUpload extends BaseComp {
  type: ComponentType.imageUpload;
  imageURL: string;
  imageSource: any;
  field: any;
  defaultValue: any;
  valueInput: any;
  placeholder: any;
  changeInput: (value: any) => any;
  prevDefaultValue: any;
}
export interface IFileUpload extends BaseComp {
  type: ComponentType.fileUpload;
  fileURL: string;
}

export enum ActionTypes {
  CREATE_OBJECT = 'createObject',
  UPDATE_OBJECT = 'updateObject',
  DELETE_OBJECT = 'deleteObject',
  UPDATE_MULTIPLE_OBJECT = 'updateMultipleObject',
  CREATE_ASSOCIATION = 'createAssociation',
  DELETE_ASSOCIATION = 'deleteAssociation',
  NAVIGATE = 'navigate',
  AUTHENTICATE = 'authenticate',
  LOGOUT = 'logout',
  SIGNUP = 'signup',
  SIGNIN = 'signin',
  UPDATE_LOGGED_IN = 'updateLoggedIn',
  FORGOT_PASSWORD = 'forgotPassword',
  SHARE = 'share',
  SET_INPUT_VALUE = 'setInputValue',
  CALL_API = 'callAPI',
  AUTHENTICATE_API = 'authAPI',
  SIGNUP_API = 'signupAPI',
  PUSH_NOTIFICATION = 'pushNotification',
  NOTIFICATION_PERMISSION = 'notificationPermission',
  EXTERNAL_LINK = 'externalLink',
  CUSTOM = 'custom',
  LINE_LOGIN = 'lineLogin',
  DOCOMO_LOGIN = 'docomoLogin',
  DID_LOGIN = 'DIDLogin',
  TRUSTDOCK_LOGIN = 'trustdockLogin',
  CHECK_VERSION = 'checkVersion',
  APPLE_LOGIN = 'appleLogin',
  SEND_EMAIL = 'sendMail',
  TRACKING_ANALYSTIC = 'googleAnalyticsEvent',
  CANCEL_UNIVAPAY_SUBSCRIPTION = 'cancelUnivaPaySubscription',
  CANCEL_CLICKPAY_SUBSCRIPTION = 'cancelClickPaySubscription',
  TORUS_LOGIN = 'TorusLogin',
  SHARE_SNS = 'share_sns',
  FOLLOW = 'follow',
  SEARCH_IN_DB = 'search_in_db',
  SEND_GIFT = 'send_gift',
  PURCHASE_PRODUCT_ALL = 'purchase_product_all',
  CUSTOM_SET_INPUT = 'customSetInput',
  LIVE_STREAM_REQUEST_REVENUES = 'live_stream_request_revenues',
  DISMISS_KEYBOARD = 'dismiss_keyboard',
  DELETE_RECORD_CUSTOM = 'delete_record_custom',
  TEST_CRASH_APP = 'test_crash_app',
  CHANGE_SELECT_VALUE = 'change_select_value',
  COPY_TO_CLIPBOARD = 'copyToClipboard',
  NOTIFICATIONS = 'notifications',
  DOWNLOAD_FILE = 'DOWNLOAD_FILE',
}

export enum Event {
  TAP = 'tap',
  LONG_PRESS = 'longPress',
}
export interface IAttrActions {
  actions: IAction[];
  eventType: Event;
  type: 'action';
}
export type TypeTransion =
  | 'TRANSITION_MODAL'
  | 'TRANSITION_PUSH'
  | 'TRANSITION_NONE'
  | 'TRANSITION_SLIDE_LEFT'
  | 'TRANSITION_SLIDE_RIGHT'
  | 'TRANSITION_SLIDE_DOWN'
  | 'TRANSITION_SLIDE_UP'
  | 'TRANSITION_FLOAT_LEFT'
  | 'TRANSITION_FLOAT_RIGHT'
  | 'TRANSITION_FLOAT_UP'
  | 'TRANSITION_FLOAT_DOWN';
export interface IAction {
  actionType: ActionTypes;
  id: string;
  options: {
    target?: string;
    transition?: TypeTransion;
    fields?: Record<string, any>[];
    tableId?: string;
    tableName?: string;
    selector?: Record<string, any>;
    url?: Record<string, any>[];
    conditionals?: any[];
    inputId?: string;
    source?: Record<string, any>;
    fieldId?: string;
    clientId?: string;
    clientSecret?: string;
    redirectUri?: string;
    scope?: string;
    custom?: Record<string, any>[];
    customAction?: ICustomAction;
    customActionId?: number;
    appId?: string;
    bundleId?: string;
    type?: string;
    filter?: any;
    eventName?: any;
  } & Obj;
  tableId?: string | any;
  autoValues?: Record<string, any>;
  type?: string;
  alert?: {
    error?: Array<string> | string;
    success?: Array<string> | string;
  };
  status: string;
  error?: IObj;
}

export interface paramsAction {
  callback?: (params: any) => any;
  record?: any;
  currentRecordIds?: Record<string, any>;
  web3Config?: Record<string, any>;
  messages: { [key: string]: any };
  dependencies: any;
  formId?: string;
  getAllResult?: boolean;
}

export interface IMap extends BaseComp {
  attributes: {
    apiKey: string;
    markerCollection: {
      bindingType: string;
      id: string;
      source: {
        dataType: string;
        datasourceId: string;
        options: {
          limit: number;
          filter: Array<Record<string, any>>;
        };
        sort: Record<string, any>;
        source: Record<string, any>[];
        tableId: string;
        type: string;
      };
      type: string;
    };
    markerType: 'multiple' | 'simple';
    marker: {
      markerSource: string;
      markerAddress: Array<string | Record<string, any>>;
      markerTypeOfPin: 'address' | 'geometry';
      markerX: {
        formula?: number;
      };
      markerY: {
        formula?: number;
      };
      selectedMarker: boolean;
      selectedAddressMarker: Array<string | Record<string, any>>;
    };
    markers: {
      markerSource: string;
      markerAddress: Array<string | Record<string, any>>;
      markerTypeOfPin: 'address' | 'geometry';
      currentLocation: boolean;
      distanceSearch: {
        formula: number;
      };
      selectedMarker: boolean;
      selectedAddressMarker: Array<string | Record<string, any>>;
    };
    style: {
      mapStyle: 'roadmap' | 'terrain' | 'hybrid' | 'satellite';
      customStyle: string;
    };
  };
  records: Array<Record<string, any>>;
  googleMapisloaded?: boolean;
  loadScriptMap?: () => void;
  getFieldValue?: any;
  currentLocation?: any;
  data: Record<string, any>;
  initializeList: boolean;
  isInCustomList: boolean;
}

export interface IAdMob {
  id: number;
  appId: string;
  iosAppId: string;
  androidAppId: string;
}

export interface ILineToken {
  data?: any;
  id_token: string;
  dataUser: any;
}

export type PropRender = {
  screen: IPage;
};

export type ObjectRenderProps = {
  keyItem?: number;
  arrComp: Array<ElementType>;
  isScreen?: boolean;
  layout?: {
    offsetTop?: number;
    offsetLeft?: number;
    originX?: number;
    parentWidth?: number;
  };
  isShowStatusBar?: boolean;
  isFromViewLive?: boolean;
  currentArrComp?: Array<ElementType>;
  screen?: IPage;
};

export type DimensionObject = {
  fontScale: number;
  height: number;
  scale: number;
  width: number;
};

export interface ILineToken {
  data?: any;
  id_token: string;
  dataUser: any;
}

export interface ICustomAction {
  config: string;
  id: number;
  name: string;
  ownerId: number;
  owner_id: number;
  createdAt: string;
  updatedAt: string;
}

export type IImagePickerType = {
  filename: string;
  id: string;
  size: number;
  url: string;
};

export interface ICarousel extends BaseComp {
  type: ComponentType.imageList;
  maximum: number;
  data: any[];
  databaseOptions: Record<string, any>;
}

export type IFilterProps = {
  records: any[];
  total: number;
  totalPage: number;
  loadingFilter: boolean;
  initializeList: boolean;
  error: string;
};

export interface IUseFilterType extends IFilterProps {
  onLoadMoreFilter: (page: number) => any;
}

export interface IPaginateOptions {
  pageSize: number;
  paginate: boolean;
  position?: string;
}

export type ListObjectProps = {
  ObjectClass: FC;
  obj: any;
  dependencies: any;
  onPress: (actionId: string, options: IOnPressProps) => any;
  dataBinding: any;
  // currToken: CancelTokenSource | any;
};

export type UrlsStream = {
  RTS: string;
  RTMP: string;
  M3U8: string;
  FLV: string;
};

export interface ILivestreamRecordInternal {
  id: string;
  stream_id: string;
  title?: string;
  ingest_domain: string;
  stream_domain: string;
  started_at?: Date;
  ended_at?: Date;
  record_info?: string;
  total_view?: number;
  is_pushed_notification?: boolean;
  total_coin?: number;
  topi_coin?: number;
  livestream_owner_id: string;
  viewer?: number;
}

export type StreamInfo = ILivestreamRecordInternal;

export interface ILiveStreamDetail extends BaseComp {
  streamInfo: StreamInfo;
  urls: UrlsStream;
}

export type IObj = Record<string, any>;
